import styled from 'styled-components';

import { grid } from '../../config';
import { media } from '../../utils/responsive';

const Grid = styled.div`
  width: calc(100% + ${grid.gutters.small}px);
  display: flex;
  flex-wrap: wrap;
  margin: -${grid.gutters.small / 2}px;

  ${media.medium`
    width: calc(100% + ${grid.gutters.medium}px);
    margin: -${grid.gutters.medium / 2}px;
  `};
`;
export default Grid;
