import { validateAll } from 'indicative/validator';

const defaultMessages = {
  required: 'This field is required',
  email: 'Please enter a valid email',
};

const formikValidation = (values, rules, messages) => {
  const validationValues = { ...values };

  for (const [field, value] of Object.entries(validationValues)) {
    if (typeof value === 'string' || typeof value !== 'object') {
      continue;
    }

    for (const [subField, subValue] of Object.entries(value)) {
      const fieldName = `${field}[${subField}]`;
      validationValues[fieldName] = subValue;
    }

    delete validationValues[field];
  }

  return new Promise((resolve) => {
    messages = Object.assign(defaultMessages, messages);

    validateAll(validationValues, rules, messages)
      .then(() => {
        resolve({});
      })
      .catch((errors) => {
        const formikErrors = {};

        for (const error of errors) {
          if (formikErrors[error.field]) {
            continue;
          }

          formikErrors[error.field] = error.message;
        }

        resolve(formikErrors);
      });
  });
};

export default formikValidation;
