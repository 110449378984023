import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { animation, colors } from '../../config';

// eslint-disable-next-line no-unused-vars
const TransientButton = ({ backgroundColor, isRounded, ...restProps }) => (
  <button {...restProps} />
);

TransientButton.propTypes = {
  backgroundColor: PropTypes.string,
  isRounded: PropTypes.bool,
};

const Button = styled(TransientButton)`
  display: block;
  padding: 10px 30px;
  border: none;
  color: ${({ backgroundColor = 'primary' }) =>
    backgroundColor === 'white' ? colors.body : colors.button.white};
  font-size: 16px;
  border-radius: ${({ isRounded = false }) => (isRounded ? 25 : 8)}px;
  background: ${({ backgroundColor = 'primary' }) =>
    colors.button[backgroundColor]};
  transition: ${animation.transition};
  overflow: hidden;
  position: relative;

  &::before {
    content: '';
    display: block;
    background: rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    transition: ${animation.transition};
    border-radius: ${({ isRounded = false }) => (isRounded ? 25 : 8)}px;
  }

  &:hover::before,
  &:focus::before {
    opacity: 1;
  }
`;

export default Button;
