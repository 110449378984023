import styled, { css } from 'styled-components';

import { grid } from '../../config';
import { grid as gridUtil } from '../../utils';
import { media } from '../../utils/responsive';

const GridCell = styled.div`
  box-sizing: border-box;
  padding: ${grid.gutters.small / 2}px;

  ${({ cellSize }) =>
    cellSize &&
    css`
      width: ${gridUtil.cellSize(cellSize)};
    `}

  ${({ cellSizeMedium }) =>
    cellSizeMedium &&
    media.medium`
        width: ${gridUtil.cellSize(cellSizeMedium)};
      `}

  ${({ cellSizeLarge }) =>
    cellSizeLarge &&
    media.large`
        width: ${gridUtil.cellSize(cellSizeLarge)};
      `}

  ${media.medium`
    padding: ${grid.gutters.medium / 2}px;
  `}
`;

export const FullCell = styled(GridCell)`
  width: ${gridUtil.cellSize(12)};
  position: relative;
`;

export const HalfCell = styled(FullCell)`
  ${media.medium`
    width: ${gridUtil.cellSize(6)};
  `};
`;

export default GridCell;
