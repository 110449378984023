import { scroller } from 'react-scroll';

import { grid } from '../config';
import { responsive } from './index';

/**
 * Scroll to an element.
 *
 * @param {string} to
 */
export function scrollTo(to) {
  let offset = -grid.gutters.small / 2;
  const mediumGutter = grid.gutters.medium / 2;

  if (responsive.min('medium')) {
    offset = -mediumGutter;
  }

  if (responsive.min('large')) {
    offset = -65 - mediumGutter;
  }

  scroller.scrollTo(to, {
    duration: 500,
    delay: 0,
    smooth: 'easeInOut',
    offset,
  });
}
